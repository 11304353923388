function updateDataAttr() {
    const endTourBtn = document.querySelectorAll(".tutorial__end");
    if (endTourBtn.length) {
        endTourBtn[endTourBtn.length-1].setAttribute('data-formelementid', 'CARE_TO_TALK-BTN-CLK-END_TUTORIAL');
        endTourBtn[endTourBtn.length-1].setAttribute('data-categoryid', 'caregiving');
        endTourBtn[endTourBtn.length-1].setAttribute('data-subcategoryid', 'care to talk');
    }

    const nextBtn = document.querySelectorAll(".next-button.shepherd-button");
    if (nextBtn.length) {
        nextBtn[nextBtn.length-1].setAttribute('data-formelementid', 'CARE_TO_TALK-BTN-CLK-NEXT_TUTORIAL');
        nextBtn[nextBtn.length-1].setAttribute('data-categoryid', 'caregiving');
        nextBtn[nextBtn.length-1].setAttribute('data-subcategoryid', 'care to talk');
    }

    const prevBtn = document.querySelectorAll(".prev-button.shepherd-button");
    if (prevBtn.length) {
        prevBtn[prevBtn.length-1].setAttribute('data-formelementid', 'CARE_TO_TALK-BTN-CLK-PREVIOUS_TUTORIAL');
        prevBtn[prevBtn.length-1].setAttribute('data-categoryid', 'caregiving');
        prevBtn[prevBtn.length-1].setAttribute('data-subcategoryid', 'care to talk');
    }
}

export default {
    methods: {
        getTourStepsForDeck(tour) {
            return [
                { // 1
                    attachTo: { element: null, on: 'center' },
                    title: '<h4 class="tutorial-title">AARP Care to Talk cards &trade;</h4><p class="tutorial-subtitle ta--center">Spark meaningful conversations with those you care for.</p>',
                    text: `
                        <div class="grid-col tutorial-video-holder">
                        <iframe
                            width="100%"
                            height="389"
                            src="https://www.youtube.com/embed/ihhW9LGzdjE"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen>
                        </iframe>
                        </div>
                        <div class="step-holder">
                            <div class="step active" onclick="emitStepEvent(1)"></div>
                            <div class="step" onclick="emitStepEvent(2)"></div>
                            <div class="step" onclick="emitStepEvent(3)"></div>
                            <div class="step" onclick="emitStepEvent(4)"></div>
                        </div>
                        <div class="close-modal-button"><img onclick="emitStepEvent(0)" src="` + window.Vapor.asset('img/close_tutorial_modal.png') + `" alt=""></div>
                    `,
                    scrollTo: { behavior: 'smooth', block: 'center' },
                    buttons: [
                        {
                            text: 'End Tutorial',
                            action: tour.complete,
                            classes: 'p tutorial__end end-tour-button'
                        },
                        {
                            text: 'Next step',
                            action() {
                                document.getElementById('my-deck-button').click();
                                setTimeout(() => {
                                    tour.next();
                                }, 300);
                            },
                            classes: 'button button--sm next-button'
                        }
                    ],
                    popperOptions: {
                        modifiers: [
                            {
                                name: 'flip',
                                options: {
                                    fallbackPlacements: ['bottom'],
                                    rootBoundary: '.mydeck-customize'
                                }
                            }
                        ]
                    },
                    when: {
                        show() {
                            updateDataAttr()
                            window.emitStepEvent = (newStep) => {
                                if (newStep === 0) {
                                    tour.complete();
                                }
                                if (newStep === 2) { // we are on the first step
                                    tour.next();
                                }
                                if (newStep === 3) {
                                    tour.show(2);
                                }

                                if (newStep === 4) {
                                    tour.show(3);
                                }
                            };
                        }
                    },
                },
                { // 2
                    attachTo: { element: null, on: 'center' },
                    title: `<h4 class="tutorial-title">Scroll to find cards helpful for your <br>caregiving conversations</h4>`,
                    text: `
                        <div class="grid-col">
                            <img src="` + window.Vapor.asset('img/tutorial_step2.png') + `" alt="" class="tutorial-img">
                        </div>
                        <div class="step-holder">
                            <div class="step" onclick="emitStepEvent(1)"></div>
                            <div class="step active" onclick="emitStepEvent(2)"></div>
                            <div class="step" onclick="emitStepEvent(3)"></div>
                            <div class="step" onclick="emitStepEvent(4)"></div>
                        </div>
                        <div class="close-modal-button" onclick="emitStepEvent(0)"><img src="` + window.Vapor.asset('img/close_tutorial_modal.png') + `" alt=""></div>
                    `,
                    scrollTo: { behavior: 'smooth', block: 'center' },
                    buttons: [
                        {
                            text: 'End Tutorial',
                            action: tour.complete,
                            classes: 'p tutorial__end end-tour-button'
                        },
                        {
                            text: 'Next step',
                            action() {
                                document.getElementById('my-deck-button').click();
                                setTimeout(() => {
                                    tour.next();
                                }, 300);
                            },
                            classes: 'button button--sm next-button'
                        }
                    ],
                    popperOptions: {
                        modifiers: [
                            {
                                name: 'flip',
                                options: {
                                    fallbackPlacements: ['bottom'],
                                    rootBoundary: '.mydeck-customize'
                                }
                            }
                        ]
                    },
                    when: {
                        show() {
                            updateDataAttr();

                            window.emitStepEvent = (newStep) => {
                                if (newStep === 0) {
                                    tour.complete();
                                }
                                if (newStep === 1) { // we are on the first step
                                    tour.back();
                                }
                                if (newStep === 3) {
                                    tour.next();
                                }

                                if (newStep === 4) {
                                    tour.show(3);
                                }
                            };
                        }
                    },
                },
                { // 3
                    attachTo: { element: '.ctt-categories__item--fav', on: 'bottom' },
                    title: `<h4 class="tutorial-title">You can find your liked cards in one place</h4>`,
                    text: `
                        <div class="grid-col">
                            <img src="` + window.Vapor.asset('img/tutorial_step3.png') + `" alt="" class="tutorial-img">
                        </div>
                        <div class="step-holder">
                            <div class="step" onclick="emitStepEvent(1)"></div>
                            <div class="step" onclick="emitStepEvent(2)"></div>
                            <div class="step active" onclick="emitStepEvent(3)"></div>
                            <div class="step" onclick="emitStepEvent(4)"></div>
                        </div>
                        <div class="close-modal-button" onclick="emitStepEvent(0)"><img src="` + window.Vapor.asset('img/close_tutorial_modal.png') + `" alt=""></div>
                    `,
                    scrollTo: { behavior: 'smooth', block: 'center' },
                    buttons: [
                        {
                            text: 'End Tutorial',
                            action: tour.complete,
                            classes: 'p tutorial__end end-tour-button'
                        },
                        {
                            text: 'Next step',
                            action() {
                                document.getElementById('my-deck-button').click();
                                setTimeout(() => {
                                    tour.next();
                                }, 300);
                            },
                            classes: 'button button--sm next-button'
                        }
                    ],
                    popperOptions: {
                        modifiers: [
                            {
                                name: 'flip',
                                options: {
                                    fallbackPlacements: ['bottom'],
                                    rootBoundary: '.category-item-holder'
                                }
                            }
                        ]
                    },
                    when: {
                        show() {
                            updateDataAttr();

                            window.emitStepEvent = (newStep) => {
                                if (newStep === 0) {
                                    tour.complete();
                                }
                                if (newStep === 1) { // we are on the first step
                                    tour.show(0);
                                }
                                if (newStep === 2) {
                                    tour.back();
                                }
                                if (newStep === 4) {
                                    tour.show(3);
                                }
                            };
                        }
                    },
                },
                { // 4
                    attachTo: { element: '#caregiving-resources-button', on: 'right' },
                    title: '<h4 class="tutorial-title">Caregiving Resources</h4>',
                    text: `
                        <div class="grid-col">
                            <p class="mt--050 step-description ta--center">Click the <strong>Caregiving Resources</strong> tab to view helpful videos.</p>
                        </div>
                        <div class="step-holder">
                            <div class="step" onclick="emitStepEvent(1)"></div>
                            <div class="step" onclick="emitStepEvent(2)"></div>
                            <div class="step" onclick="emitStepEvent(3)"></div>
                            <div class="step active" onclick="emitStepEvent(4)"></div>
                        </div>
                        <div class="close-modal-button" onclick="emitStepEvent(0)"><img src="` + window.Vapor.asset('img/close_tutorial_modal.png') + `" alt=""></div>
                    `,
                    scrollTo: { behavior: 'smooth', block: 'center' },
                    buttons: [
                        {
                            text: 'End Tutorial',
                            action: tour.complete,
                            classes: 'p tutorial__end end-tour-button inverse'
                        }
                    ],
                    popperOptions: {
                        modifiers: [
                            {
                                name: 'flip',
                                options: {
                                    fallbackPlacements: ['bottom'],
                                    rootBoundary: '.mydeck-customize'
                                }
                            }
                        ]
                    },
                    when: {
                        show() {
                            updateDataAttr();

                            window.emitStepEvent = (newStep) => {
                                if (newStep === 0) {
                                    tour.complete();
                                }
                                if (newStep === 1) { // we are on the first step
                                    tour.show(0);
                                }
                                if (newStep === 2) {
                                    tour.show(1);
                                }
                                if (newStep === 3) {
                                    tour.back();
                                }
                            };
                        }
                    },
                },
            ];
        },
        getTourStepsForMobileDeck(tour) {
            return [
                { // 1
                    attachTo: { element: null, on: 'center' },
                    title: '<h4 class="tutorial-title">AARP Care to Talk cards &trade;</h4><p class="tutorial-subtitle ta--center">Conversations with those you care for can be difficult.</p>',
                    text: `
                        <div class="grid-col tutorial-video-holder">
                        <iframe
                            width="100%"
                            height="201"
                            src="https://www.youtube.com/embed/ihhW9LGzdjE"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen>
                        </iframe>
                        </div>
                        <div class="step-holder">
                            <div class="step active" onclick="emitStepEvent(1)"></div>
                            <div class="step" onclick="emitStepEvent(2)"></div>
                            <div class="step" onclick="emitStepEvent(3)"></div>
                            <div class="step" onclick="emitStepEvent(4)"></div>
                        </div>
                        <div class="close-modal-button"><img onclick="emitStepEvent(0)" src="` + window.Vapor.asset('img/close_tutorial_mobile.png') + `" alt=""></div>
                    `,
                    scrollTo: { behavior: 'smooth', block: 'center' },
                    classes: 'tutorial-mobile-step',
                    buttons: [
                        {
                            text: 'End Tutorial',
                            action: tour.complete,
                            classes: 'p tutorial__end end-tour-button'
                        },
                        {
                            text: 'Next step',
                            action() {
                                document.getElementById('my-deck-button').click();
                                setTimeout(() => {
                                    tour.next();
                                }, 300);
                            },
                            classes: 'button button--sm next-button'
                        }
                    ],
                    popperOptions: {
                        modifiers: [
                            {
                                name: 'flip',
                                options: {
                                    fallbackPlacements: ['bottom'],
                                    rootBoundary: '.mydeck-customize'
                                }
                            }
                        ]
                    },
                    when: {
                        show() {
                            updateDataAttr()
                            window.emitStepEvent = (newStep) => {
                                if (newStep === 0) {
                                    tour.complete();
                                }
                                if (newStep === 2) { // we are on the first step
                                    tour.next();
                                }
                                if (newStep === 3) {
                                    tour.show(2);
                                }

                                if (newStep === 4) {
                                    tour.show(3);
                                }
                            };
                        }
                    },
                },
                { // 2
                    attachTo: { element: null, on: 'center' },
                    title: `<h4 class="tutorial-title">Scroll to find cards helpful for your caregiving conversations</h4>`,
                    text: `
                        <div class="grid-col">
                            <img src="` + window.Vapor.asset('img/tutorial_step2_mobile.png') + `" alt="" class="tutorial-img">
                        </div>
                        <div class="step-holder">
                            <div class="step" onclick="emitStepEvent(1)"></div>
                            <div class="step active" onclick="emitStepEvent(2)"></div>
                            <div class="step" onclick="emitStepEvent(3)"></div>
                            <div class="step" onclick="emitStepEvent(4)"></div>
                        </div>
                        <div class="close-modal-button"><img onclick="emitStepEvent(0)" src="` + window.Vapor.asset('img/close_tutorial_mobile.png') + `" alt=""></div>
                    `,
                    scrollTo: { behavior: 'smooth', block: 'center' },
                    classes: 'tutorial-mobile-step',
                    buttons: [
                        {
                            text: 'End Tutorial',
                            action: tour.complete,
                            classes: 'p tutorial__end end-tour-button'
                        },
                        {
                            text: 'Next step',
                            action() {
                                document.getElementById('my-deck-button').click();
                                setTimeout(() => {
                                    tour.next();
                                }, 300);
                            },
                            classes: 'button button--sm next-button'
                        }
                    ],
                    popperOptions: {
                        modifiers: [
                            {
                                name: 'flip',
                                options: {
                                    fallbackPlacements: ['bottom'],
                                    rootBoundary: '.mydeck-customize'
                                }
                            }
                        ]
                    },
                    when: {
                        show() {
                            updateDataAttr();

                            window.emitStepEvent = (newStep) => {
                                if (newStep === 0) {
                                    tour.complete();
                                }
                                if (newStep === 1) { // we are on the first step
                                    tour.back();
                                }
                                if (newStep === 3) {
                                    tour.next();
                                }
                                if (newStep === 4) {
                                    tour.show(3);
                                }
                            };
                        }
                    },
                },
                { // 3
                    attachTo: { element: '.ctt-categories__item--fav', on: 'bottom' },
                    title: `<h4 class="tutorial-title last-step-title">You can find your liked <br> cards in one place</h4>`,
                    text: `
                        <div class="step-holder">
                            <div class="step" onclick="emitStepEvent(1)"></div>
                            <div class="step" onclick="emitStepEvent(2)"></div>
                            <div class="step active" onclick="emitStepEvent(3)"></div>
                            <div class="step" onclick="emitStepEvent(4)"></div>
                        </div>
                        <div class="close-modal-button" ><img onclick="emitStepEvent(0)" src="` + window.Vapor.asset('img/close_tutorial_mobile.png') + `" alt=""></div>
                    `,
                    scrollTo: { behavior: 'smooth', block: 'center' },
                    classes: 'tutorial-mobile-step',
                    buttons: [
                        {
                            text: 'End Tutorial',
                            action: tour.complete,
                            classes: 'p tutorial__end end-tour-button'
                        },
                        {
                            text: 'Next step',
                            action() {
                                document.getElementById('my-deck-button').click();
                                setTimeout(() => {
                                    tour.next();
                                }, 300);
                            },
                            classes: 'button button--sm next-button'
                        }
                    ],
                    popperOptions: {
                        modifiers: [
                            {
                                name: 'flip',
                                options: {
                                    fallbackPlacements: ['bottom'],
                                    rootBoundary: '.mydeck-customize'
                                }
                            }
                        ]
                    },
                    when: {
                        show() {
                            updateDataAttr();

                            window.emitStepEvent = (newStep) => {
                                if (newStep === 0) {
                                    tour.complete();
                                }
                                if (newStep === 1) { // we are on the first step
                                    tour.show(0);
                                }
                                if (newStep === 2) {
                                    tour.back();
                                }
                                if (newStep === 4) {
                                    tour.show(3);
                                }
                            };
                        }
                    },
                },
                { // 4
                    attachTo: { element: '#caregiving-resources-button', on: 'right' },
                    title: `<h4 class="tutorial-title last-step-title">Tap the <strong>Caregiving Resources</strong> tab to view helpful videos.</h4>`,
                    text: `
                        <div class="step-holder">
                            <div class="step" onclick="emitStepEvent(1)"></div>
                            <div class="step" onclick="emitStepEvent(2)"></div>
                            <div class="step" onclick="emitStepEvent(3)"></div>
                            <div class="step active" onclick="emitStepEvent(4)"></div>
                        </div>
                        <div class="close-modal-button" style="top: -100px" ><img onclick="emitStepEvent(0)" src="` + window.Vapor.asset('img/close_tutorial_mobile.png') + `" alt=""></div>
                    `,
                    scrollTo: { behavior: 'smooth', block: 'center' },
                    classes: 'tutorial-mobile-step last-mobile-step',
                    buttons: [
                        {
                            text: 'End Tutorial',
                            action: tour.complete,
                            classes: 'p tutorial__end end-tour-button inverse'
                        }
                    ],
                    popperOptions: {
                        modifiers: [
                            {
                                name: 'flip',
                                options: {
                                    fallbackPlacements: ['bottom'],
                                    rootBoundary: '.mydeck-customize'
                                }
                            }
                        ]
                    },
                    when: {
                        show() {
                            updateDataAttr();

                            window.emitStepEvent = (newStep) => {
                                if (newStep === 0) {
                                    tour.complete();
                                }
                                if (newStep === 1) { // we are on the first step
                                    tour.show(0);
                                }
                                if (newStep === 2) {
                                    tour.show(1);
                                }
                                if (newStep === 3) {
                                    tour.back();
                                }
                            };
                        }
                    },
                },
            ];;
        },
        tourIsCompleteFor(key, guestId) {
            let storageKey = this.localStoragePath(key, guestId);

            return !!localStorage.getItem(storageKey);
        },
        completeTourForGuest(key, guestId) {
            let storageKey = this.localStoragePath(key, guestId);

            localStorage.setItem(storageKey, Date.now());
        },
        localStoragePath(key, guestId) {
            return 'tour#' + guestId + '#' + key;
        },
        restartTourForGuest(key, guestId) {
            let storageKey = this.localStoragePath(key, guestId);

            localStorage.removeItem(storageKey);
            window.location = window.location.pathname;
        },
    }
};
