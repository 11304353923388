<template>
    <nav class="ctt-subheader">
        <div class="ctt-tabs">
            <div class="mydeck-customize" style="display: flex; flex-grow: 1;" v-if="isMobile">
                <a
                    @click.stop="switchTab('my_deck')"
                    class="ctt-tabs__item mydeck-customize-btn"
                    id="my-deck-button"
                    tabindex="0"
                    aria-label="My Deck"
                    role="button"
                    :class="{ active: deckTab === 'my_deck' }"
                    data-formelementid="CARE_TO_TALK-BTN-CLK-MY_DECK"
                    data-categoryid="caregiving"
                    data-subcategoryid="care to talk"
                    >
                    {{ !sharedDeckFrom ? 'Cards' : sharedDeckFrom.name + "'s Deck" }}
                </a>
            </div>
            <div class="mydeck-customize" style="display: flex; flex-grow: 1;" v-if="!isMobile">
                <a
                    @click.stop="switchTab('my_deck')"
                    class="ctt-tabs__item mydeck-customize-btn"
                    id="my-deck-button"
                    :class="{ active: deckTab === 'my_deck' }"
                    tabindex="0"
                    aria-label="My Deck"
                    role="button"
                    data-formelementid="CARE_TO_TALK-BTN-CLK-MY_DECK"
                    data-categoryid="caregiving"
                    data-subcategoryid="care to talk"
                    >{{
                        !sharedDeckFrom ? 'Cards' : sharedDeckFrom.name + "'s AARP Care To Talk?&trade; conversation cards"
                    }}</a
                >
            </div>
            <div id="caregiving-resources-button" style="display: flex; flex-grow: 1;">
                <a
                    v-if="!sharedDeckFrom"
                    @click.stop="switchTab('caregiving_resources')"
                    class="ctt-tabs__item caregiving-resources-btn"
                    id="caregiving-resources-clickable"
                    :class="{ active: deckTab === 'caregiving_resources' }"
                    tabindex="0"
                    aria-label="Resources"
                    role="button"
                    data-formelementid="CARE_TO_TALK-BTN-CLK-CAREGIVING_RESOURCES"
                    data-categoryid="caregiving"
                    data-subcategoryid="care to talk"
                    >
                    Caregiving Resources
                </a>
                <a
                    v-else
                    href="/"
                    class="ctt-tabs__item"
                    tabindex="0"
                    aria-label="Create my own deck"
                    role="button"
                >
                    Create my own deck
                </a>
            </div>
        </div>

        <div class="ctt-switcher" v-if="deckTab === 'my_deck'">
            <button
                @click.prevent="switchView('stack')"
                class="ctt-switcher__item"
                :class="{ active: deckView === 'stack' }"
                tabindex="0"
                aria-label="Stack view"
                role="button"
                data-formelementid="CARE_TO_TALK-BTN-CLK-VIEW-STACK"
                data-categoryid="caregiving"
                data-subcategoryid="care to talk"
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="16" viewBox="0 0 21 16">
                    <g id="Group_198" data-name="Group 198" transform="translate(-786.321 -299.5)">
                        <rect id="Rectangle_69" data-name="Rectangle 69" width="11" height="16" transform="translate(791.321 299.5)" fill="#8b8b8b"/>
                        <rect id="Rectangle_70" data-name="Rectangle 70" width="3" height="14" transform="translate(786.321 300.5)" fill="#c6c6c6"/>
                        <rect id="Rectangle_71" data-name="Rectangle 71" width="3" height="14" transform="translate(804.321 300.5)" fill="#c6c6c6"/>
                    </g>
                </svg>

                Stack
            </button>
            <button
                @click.prevent="switchView('grid')"
                class="ctt-switcher__item"
                :class="{ active: deckView === 'grid' }"
                tabindex="0"
                aria-label="Grid view"
                role="button"
                data-formelementid="CARE_TO_TALK-BTN-CLK-VIEW-GRID"
                data-categoryid="caregiving"
                data-subcategoryid="care to talk"
            >
                <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="currentColor"
                    aria-label="Grid view"
                >
                    <rect width="6.42857" height="6.42857" rx="1"></rect>
                    <rect x="8.57143" width="6.42857" height="6.42857" rx="1"></rect>
                    <rect y="8.57143" width="6.42857" height="6.42857" rx="1"></rect>
                    <rect x="8.57143" y="8.57143" width="6.42857" height="6.42857" rx="1"></rect>
                </svg>
                Grid
            </button>
        </div>

        <div class="deck-nav">
            <a
                @click.stop="openSuggestionModal"
                class="deck-nav__item"
                tabindex="0"
                aria-label="Suggest new card"
                role="button"
                data-formelementid="CARE_TO_TALK-BTN-CLK-SUGGEST_NEW"
                data-categoryid="caregiving"
                data-subcategoryid="care to talk"
            >
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" aria-hidden="true">
                    <path
                        d="M9.33317 1.33333H3.99984C3.64622 1.33333 3.30708 1.47381 3.05703 1.72386C2.80698 1.9739 2.6665 2.31304 2.6665 2.66667V13.3333C2.6665 13.687 2.80698 14.0261 3.05703 14.2761C3.30708 14.5262 3.64622 14.6667 3.99984 14.6667H11.9998C12.3535 14.6667 12.6926 14.5262 12.9426 14.2761C13.1927 14.0261 13.3332 13.687 13.3332 13.3333V5.33333L9.33317 1.33333Z"
                        fill="currentColor"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                    <path
                        d="M9.3335 0.333332V4.33333C9.3335 4.88562 9.78121 5.33333 10.3335 5.33333H14.3335"
                        stroke="white"
                        stroke-linecap="square"
                        stroke-linejoin="round"
                    ></path>
                    <path
                        d="M8 12V8"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="square"
                        stroke-linejoin="round"
                    ></path>
                    <path
                        d="M6 10H10"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="square"
                        stroke-linejoin="round"
                    ></path>
                </svg>
                Suggest New
            </a>
            <a
                @click="printDeck()"
                class="deck-nav__item"
                tabindex="0"
                aria-label="Print deck"
                role="button"
                data-formelementid="CARE_TO_TALK-BTN-CLK-PRINT_MY_DECK"
                data-categoryid="caregiving"
                data-subcategoryid="care to talk"
            >
                <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    aria-hidden="true"
                >
                    <path d="M13 0H3V3H13V0Z"></path>
                    <path
                        d="M15 5H1C0.4 5 0 5.4 0 6V12C0 12.6 0.4 13 1 13H3V15C3 15.6 3.4 16 4 16H12C12.6 16 13 15.6 13 15V13H15C15.6 13 16 12.6 16 12V6C16 5.4 15.6 5 15 5ZM11 14H5V9H11V14Z"
                    ></path>
                </svg>
                Print Cards
            </a>
            <template v-if="!sharedDeckFrom">
                <a
                    href="#"
                    class="deck-nav__item deck-nav__item--share"
                    tabindex="0"
                    aria-label="Share my deck"
                    role="button"
                    data-formelementid="CARE_TO_TALK-BTN-CLK-SHARE_MY_DECK"
                    data-categoryid="caregiving"
                    data-subcategoryid="care to talk"
                >
                    <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="square"
                        stroke-linejoin="round"
                        aria-hidden="true"
                    >
                        <path
                            d="M2 8V13.6C2 13.9713 2.15804 14.3274 2.43934 14.5899C2.72064 14.8525 3.10218 15 3.5 15H12.5C12.8978 15 13.2794 14.8525 13.5607 14.5899C13.842 14.3274 14 13.9713 14 13.6V8"
                        ></path>
                        <path d="M10.6668 3.99999L8.00016 1.33333L5.3335 3.99999"></path>
                        <path d="M8 2.33333V9.00333"></path>
                    </svg>
                    Share My Deck
                </a>
                <div class="deck-nav__dropdown">
                    <a
                        @click.stop="copyShareUrl()"
                        class="deck-nav__option"
                        tabindex="0"
                        aria-label="Copy link"
                        role="button"
                        data-formelementid="CARE_TO_TALK-BTN-CLK-SHARE_MY_DECK-COPY_LINK"
                        data-categoryid="caregiving"
                        data-subcategoryid="care to talk"
                    >
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                d="M4.52174 16C3.32919 16 2.23602 15.5 1.34161 14.7C-0.447205 12.9 -0.447205 10.1 1.34161 8.3L2.03727 7.6L3.42857 9L2.73292 9.7C1.73913 10.7 1.73913 12.3 2.73292 13.3C3.72671 14.3 5.31677 14.3 6.31056 13.3L9.29193 10.3C10.2857 9.3 10.2857 7.7 9.29193 6.7L8.59627 6L9.98758 4.6L10.6832 5.3C12.472 7.1 12.472 9.9 10.6832 11.7L7.70186 14.7C6.90683 15.5 5.71429 16 4.52174 16Z"
                            ></path>
                            <path
                                d="M6.01242 11.4L5.31677 10.7C3.52795 8.9 3.52795 6.1 5.31677 4.3L8.29814 1.3C9.19255 0.4 10.2857 0 11.4783 0C12.6708 0 13.764 0.5 14.6584 1.3C16.4472 3.1 16.4472 5.9 14.6584 7.7L13.9627 8.4L12.5714 7L13.2671 6.3C14.2609 5.3 14.2609 3.7 13.2671 2.7C12.2733 1.7 10.6832 1.7 9.68944 2.7L6.70807 5.7C5.71429 6.7 5.71429 8.3 6.70807 9.3L7.40373 10L6.01242 11.4Z"
                            ></path>
                        </svg>
                        Copy Link
                    </a>
                    <a
                        @click="sendMail()"
                        class="deck-nav__option"
                        target="_blank"
                        tabindex="0"
                        aria-label="Email link"
                        role="button"
                        data-formelementid="CARE_TO_TALK-BTN-CLK-SHARE_MY_DECK-EMAIL"
                        data-categoryid="caregiving"
                        data-subcategoryid="care to talk"
                    >
                        <svg
                            width="16"
                            height="14"
                            viewBox="0 0 16 14"
                            fill="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                d="M14 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2L0 2.4L8 6.9L16 2.5V2C16 1.46957 15.7893 0.960859 15.4142 0.585786C15.0391 0.210714 14.5304 0 14 0Z"
                            ></path>
                            <path
                                d="M7.5 8.89974L0 4.69974V11.9997C0 12.5302 0.210714 13.0389 0.585786 13.4139C0.960859 13.789 1.46957 13.9997 2 13.9997H14C14.5304 13.9997 15.0391 13.789 15.4142 13.4139C15.7893 13.0389 16 12.5302 16 11.9997V4.69974L8.5 8.89974C8.3424 8.96898 8.17214 9.00473 8 9.00473C7.82786 9.00473 7.6576 8.96898 7.5 8.89974Z"
                            ></path>
                        </svg>
                        Email
                    </a>
                </div>
            </template>
        </div>
    </nav>
</template>
<script>
import { mapGetters } from 'vuex';
import collectorActions from '../../../mixins/collectorActions';
import Tour from '../../../mixins/Tour';
import Rewards from "../../../mixins/rewards";

export default {
    name: 'deck-subheader',
    mixins: [collectorActions, Tour, Rewards],
    data() {
        return {
            overlay: false
        };
    },
    mounted() {
        let hash = window.location.hash;
        hash = hash.substring(1);
        if (hash) {
            this.$store.commit('SET_DECK_TAB', hash);
        }
    },
    computed: {
        ...mapGetters({
            guest: 'guest',
            deckView: 'deckView',
            deckTab: 'deckTab',
            sharedDeckFrom: 'sharedDeckFrom',
            forceTab: 'forceTab',
            dismissedLoginRegisterModal: 'dismissedLoginRegisterModal'
        }),
        cardsUrl() {
            return window.location.origin + '/shared-deck/' + this.guest.uuid;
        },
    },
    methods: {
        switchTab(tabName) {
            this.switchView('grid');
            this.$store.commit('SET_DECK_TAB', tabName);
            window.location.hash = tabName;

            switch (tabName) {
                case 'my_deck':
                    this.deckMyDeck();
                    break;
                case 'customize':
                    this.deckCustomize();
                    break;
                case 'caregiving_resources':
                    this.deckCaregivingResources();
                    break;
                default:
                    this.deckMyDeck();
            }
        },
        switchView(viewType) {
            if (viewType === 'grid') {
                this.deckSelectGridView();
            } else {
                this.deckSelectStackView();
            }
            this.$store.commit('SET_DECK_VIEW', viewType);
        },
        openSuggestionModal() {
            this.$store.commit('SET_SHOW_SUGGESTION_MODAL', true);
            this.deckOpenSuggestModal();
        },
        async copyShareUrl() {
            this.distributeReward(this.guest);
            console.log('copying url...', this.cardsUrl);
            //@TODO very low priority or maybe not even necessary, but worth mentioning:
            // technically we can get into a state here where the user clicks the copy email link and nothing happens.
            // this would happen if (this.guest.user_fedid && AARP.store.getState().cookie.fedid !== undefined) below,
            // but shouldShowLoginModal returns false in DeckView.vue. It happens for me locally if I manually set a fake cookie
            // with the /cookie route but am not actually logged in.
            if (this.guest.user_fedid && AARP.store.getState().cookie.fedid !== undefined) {
                try {
                    if (! AARP.store.getState().user.rewardsUser && ! this.dismissedRewardsModal) {
                        this.$store.commit('SET_SHOW_REWARDS_MODAL', true);
                    }
                    this.generateAndCopyShareLink();
                } catch($e) {
                    console.log($e);
                }
            } else {
                this.$store.commit('SET_SHOW_LOGIN_MODAL', true);
            }
        },
        generateAndCopyShareLink() {
            this.deckShareLink();
            this.copyToClipboard(this.cardsUrl);
            this.$store.commit('SET_SNACKBAR_MESSAGE', 'Deck URL copied to clipboard');
        },
        printDeck() {
            this.distributeReward(this.guest);

            if (this.guest.user_fedid && AARP.store.getState().cookie.fedid !== undefined) {
                if (! AARP.store.getState().user.rewardsUser && ! this.dismissedRewardsModal) {
                    this.$store.commit('SET_SHOW_REWARDS_MODAL', true);
                }

                this.deckPrint();
                window.open('/deck/print', '_blank');
            } else {
                this.$store.commit('SET_SHOW_LOGIN_MODAL', true);
            }
        },
        sendMail() {
            // @todo-SSO when SSO ready make this work
            this.distributeReward(this.guest);

            if (this.guest.user_fedid && AARP.store.getState().cookie.fedid !== undefined) {
                if (! AARP.store.getState().user.rewardsUser && !this.dismissedRewardsModal) {
                    this.$store.commit('SET_SHOW_REWARDS_MODAL', true);
                }

                this.shareEmail();
            } else {
                this.$store.commit('SET_SHOW_LOGIN_MODAL', true);
            }
        },
        shareEmail() {
            this.deckShareEmail();

            this.$store.dispatch('sendAARPEmail', {
                email: AARP.store.getState().cookie.at.e,
                fedid: AARP.store.getState().cookie.fedid,
            }).then(res => {
                if (res.status === 200) {
                    this.$store.commit('SET_SNACKBAR_MESSAGE', 'Email sent to ' + AARP.store.getState().cookie.at.e);
                }
            }).catch(error => {
                console.log(error);
            });;
        },
    },
    watch: {
        deckTab(newValue, oldValue) {
            this.switchTab(newValue);
        },
        forceTab(newValue) {
            this.switchTab(newValue);
        },
        //@todo maybe move this? not sure. Cleanest place codewise would be the rewards mixin?
        guest(){
          //let's give the guest reward if they earned it before the oauth redirect:
          this.checkIfGuestNeedsReward(this.guest);
        }
    }
};
</script>

<style lang="scss">
.p-width--100 {
    width: 100%;
}
</style>
